<template>
  <MTmpTutorClassTemplate :data-list="dataList"></MTmpTutorClassTemplate>
</template>

<script>
  import MTmpTutorClassTemplate from "@/m-views/m-tmp/tutor-class/MTmpTutorClassTemplate";
  export default {
    name: "MTmpTutorClass",
    components: {
      MTmpTutorClassTemplate
    },
    metaInfo: {
      title: '少年财商素质教育导师培训班',
    },
    data() {
      return {
        dataList: [
          {
            title: '【1128】第四期少年财商素质教育导师培训班（上午）',
            time: '时间：11月28日 07：30-12：20',
            link: 'https://event.3188.la/1957694565'
          },
          {
            title: '【1128】第四期少年财商素质教育导师培训班（下午）',
            time: '时间：11月28日 13：30-18：25',
            link: 'https://event.3188.la/1957709851'
          },
          {
            title: '【1128】第四期少年财商素质教育导师培训班（晚上）',
            time: '时间：11月28日 19：00-20：30',
            link: 'https://event.3188.la/1957748557'
          },
          {
            title: '【1129】第四期少年财商素质教育导师培训班（上午）',
            time: '时间：11月29日 08：30-12：20',
            link: 'https://event.3188.la/1957748668'
          },
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
